import React from 'react'


const Boxe = ({ children, className }) => {

    return (
        <div  className="
        
       
        bg-gray-500 rounded-md  shadow-2xl custom-shadow left-20  h-96 right-4  w-4/5 rounded-xl 
        relative   
         max-sm:  max-sm:left-0 max-sm:w-full 
           max-md:   max-md:left-0 max-md:w-full   
           max-lg:  max-lg:left-0 max-lg:w-full
          max-xl:  max-xl:left-0 max-xl:w-full
        "
        
        >{children} </div>
    )
}

export default Boxe