import React from 'react';

const Boxevideo = ({ children, className }) => {
    return (
        <div className='  relative left-[-5%]  w-[110%]  h-[80%] top-[-20%]  rounded-tl-2xl rounded-tr-2xl      
        max-sm:left-[-190%]  max-sm:w-[90%]  max-sm:h-[80%] 
       
        
        '
        
        
        
        >
            {children}
        </div>
    );
};

export default Boxevideo;