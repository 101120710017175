import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCirclePlay } from '@fortawesome/free-regular-svg-icons'; // Importer l'icône





const Player = ({children,onClick}) => {


    const Style ={
        color: 'white',
        fontFamily: 'Scada , sans-serif', 
       };
    


    return (
       
              <button className="   text-wihte-500  hover:text-purple-300     " style={Style}
              
              onClick={onClick}
              
              >   
            <span className="text-xl text-wihte-500  hover:text-purple-300    mr-2"> {/* Ajout d'une marge à droite */}
                <FontAwesomeIcon icon={faCirclePlay} />
            </span>
       
          
                player 

                {children}
                </button> 
               
        
    );
};

export default Player;