import React, { useEffect, useState } from "react";
import Player from "../composant/Player"; // Importer le composant Player
// Importer le composant Headervdo
import Boxevideo from "../composant/Boxevideo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons'; // Importer l'icône de lecture


function Modal6({ onClose }) {

 // Exemple de contenu HTML à minifier
 
   const style = {
    color: 'white',
    fontFamily: 'Dreamlands, sans-serif', 
    
 };

  // État pour gérer l'animation d'ouverture du modal
  const [isOpen, setIsOpen] = useState(false);

  // État pour savoir si le contenu caché ("Découvrir") est ouvert ou fermé
  const [isContentOpen, setIsContentOpen] = useState(false);

  // État pour garder en mémoire l'index de l'épisode actuellement sélectionné
  const [currentEpisodeIndex, setCurrentEpisodeIndex] = useState(0);

  // État pour gérer l'index de l'iframe actuellement affichée dans l'épisode sélectionné
  const [currentIframeIndex, setCurrentIframeIndex] = useState(0);

  // Lance l'animation d'ouverture du modal dès que le composant est monté
  useEffect(() => {
    setIsOpen(true); // Active l'animation
  }, []);

  // Exemple de contenu HTML à minifier
 

  // Liste des épisodes, chacun ayant des URLs d'iframe, une description et un titre
  const episodes = [

    {
        id:' Vinland Saga S2 EP24' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5194290 ", " https://sendvid.com/embed/xkdmz4a5  "],
        description: " ",
        title: "",
      },



    {
        id:'Vinland Saga S2  EP23  ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5187492 ", " https://sendvid.com/embed/6x6gwgzq   "],
        description: " ",
        title: "",
      },

    

   


    {
        id:'Vinland Saga S2 EP22  ' ,
        urls: ["   https://video.sibnet.ru/shell.php?videoid=5181771 ", "  https://sendvid.com/embed/zkn2045v "],
        description: " ",
        title: "",
      },


    {
        id:'Vinland Saga S2 EP21 ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5176587  ", " https://sendvid.com/embed/t6nq382j "],
        description: " ",
        title: "",
      },


    {
        id:'Vinland Saga S2 EP20 ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5169877  ", " https://sendvid.com/embed/wbh4qpzw"],
        description: " ",
        title: "",
      },


    {
        id:'Vinland Saga S2 EP19   ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5163201 ", " https://sendvid.com/embed/kdojlh61 "],
        description: " ",
        title: "",
      },



    {
        id:' Vinland Saga S2 EP18' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5156147", " https://sendvid.com/embed/dbph1xd8"],
        description: " ",
        title: "",
      },


    {
        id:' Vinland Saga S2 EP17  ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5149599  ", "https://sendvid.com/embed/fogqtomw  "],
        description: " ",
        title: "",
      },





    {
        id:'  Vinland Saga S2 EP16' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=5141307  ", " https://sendvid.com/embed/xp1lkthi  "],
        description: " ",
        title: "",
      },





    {
        id:' Vinland Saga S2 EP15 ' ,
        urls: ["  https://video.sibnet.ru/shell.php?videoid=5130404 ", " https://sendvid.com/embed/vxv63cms   "],
        description: " ",
        title: "",
      },




    {
        id:' Vinland Saga S2 EP14' ,
        urls: ["  https://video.sibnet.ru/shell.php?videoid=5123645  ", " https://sendvid.com/embed/xn0q6mcs "],
        description: " ",
        title: "",
      },



    {
        id:'  Vinland Saga S2  EP13  ' ,
        urls: ["https://video.sibnet.ru/shell.php?videoid=5116910 ", "  https://sendvid.com/embed/xiid83wb  "],
        description: " ",
        title: "",
      },



    {
      id:' Vinland Saga S2  EP12'  ,
      urls: ["https://video.sibnet.ru/shell.php?videoid=5108678  ", " https://sendvid.com/embed/sz28bhye  "],
      description: "  ",
      title: "2",
    },
   
    {
      id:  'Vinland Saga S2 EP11'   , 
      urls: [" https://video.sibnet.ru/shell.php?videoid=5102021 ", " https://sendvid.com/embed/aucw5z25"],
      description: "  ",
      title: "1119",
    },
   

    {
      id: 'Vinland Saga S2 EP10',
      urls: ["  https://video.sibnet.ru/shell.php?videoid=5095610 ", " https://sendvid.com/embed/ddy1mc5s  "],
      description: " ",
      title: "5",
    },

   
  {
    id:  ' Vinland Saga S2 EP9' ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=5089301 ", " https://sendvid.com/embed/06w4ue05  "],
    description: "    ",
    title: "4",
  },


  {
    id:  'Vinland Saga S2 EP8'   ,
    urls: ["https://video.sibnet.ru/shell.php?videoid=5081652    ", " https://sendvid.com/embed/y4d6hdix   "],
    description: "   ",
    title: "1121",
  },
 
  {
    id:'Vinland Saga S2 EP7'  ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=5075340  ", " https://sendvid.com/embed/b12ni1jv  "],
    description: "  ",
    title: "2",
  },
 
  {
    id:  'Vinland Saga S2 EP6'   ,
    urls: ["  https://video.sibnet.ru/shell.php?videoid=5069392 ", "  https://sendvid.com/embed/c9c6c61l  "],
    description: "  ",
    title: "1119",
  },
 

    {
      id: 'Vinland Saga S2 EP5',
      urls: ["https://video.sibnet.ru/shell.php?videoid=5061067 ", " https://sendvid.com/embed/bft7oiaa  "],
      description: " ",
      title: "5",
    },

   
  {
    id:  'Vinland Saga  S2 EP4' ,
    urls: ["  https://video.sibnet.ru/shell.php?videoid=5052822 ", " https://sendvid.com/embed/t9cm6uqv    "],
    description: "    ",
    title: "4",
  },


  {
    id:  'Vinland Saga S2 EP3'   ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=5044600   ", " https://sendvid.com/embed/vzb0gmn1    "],
    description: "   ",
    title: "1121",
  },
 
  {
    id:'Vinland Saga  S2 EP2'  ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=5033731 ", "  https://sendvid.com/embed/8qq0v3xe "],
    description: "  ",
    title: "2",
  },
 
  {
    id:  'Vinland Saga S2 EP1'   ,
    urls: ["  https://video.sibnet.ru/shell.php?videoid=5026921  ", "https://vidmoly.to/embed-1vlxp3bqt4e8.html  "],
    description: "  ",
    title: "1119",
  },
 
   

    {
        id:' Vinland Saga   EP24' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943897  ", " https://sendvid.com/embed/pdpmb2vh "],
        description: " ",
        title: "",
      },



    {
        id:'Vinland Saga   EP23  ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943894 ", "  https://sendvid.com/embed/80gvboe2 "],
        description: " ",
        title: "",
      },

    

   


    {
        id:'Vinland Saga   EP22  ' ,
        urls: ["  https://video.sibnet.ru/shell.php?videoid=4943891  ", " https://sendvid.com/embed/f35w5vf9 "],
        description: " ",
        title: "",
      },


    {
        id:'Vinland Saga   EP21 ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943890 ", " https://sendvid.com/embed/29fgcifv  "],
        description: " ",
        title: "",
      },


    {
        id:'Vinland Saga  EP20 ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943886", " https://sendvid.com/embed/0j8xsku5 "],
        description: " ",
        title: "",
      },


    {
        id:'Vinland Saga  EP19   ' ,
        urls: ["https://video.sibnet.ru/shell.php?videoid=4943879  ", " https://sendvid.com/embed/untizt41 "],
        description: " ",
        title: "",
      },



    {
        id:' Vinland Saga  EP18' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943873 ", "  https://sendvid.com/embed/6m3s90zt"],
        description: " ",
        title: "",
      },


    {
        id:' Vinland Saga   EP17  ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943863", " https://sendvid.com/embed/ptsjz71y"],
        description: " ",
        title: "",
      },





    {
        id:'  Vinland Saga  EP16' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943848   ", " https://sendvid.com/embed/6drvp8bx"],
        description: " ",
        title: "",
      },





    {
        id:' Vinland Saga  EP15 ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943833", "https://sendvid.com/embed/ncd61li7 "],
        description: " ",
        title: "",
      },




    {
        id:' Vinland Saga  EP14' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943822 ", " https://sendvid.com/embed/023vi17a "],
        description: " ",
        title: "",
      },



    {
        id:'  Vinland Saga  EP13  ' ,
        urls: [" https://video.sibnet.ru/shell.php?videoid=4943816 ", " https://sendvid.com/embed/ug6vmev3 "],
        description: " ",
        title: "",
      },



    {
      id:' Vinland Saga   EP12'  ,
      urls: [" https://video.sibnet.ru/shell.php?videoid=4943807 ", " https://sendvid.com/embed/nnkpnea9 "],
      description: "  ",
      title: "2",
    },
   
    {
      id:  'Vinland Saga  EP11'   , 
      urls: [" https://video.sibnet.ru/shell.php?videoid=4943797 ", " https://sendvid.com/embed/cs3t3ufy "],
      description: "  ",
      title: "1119",
    },
   

    {
      id: 'Vinland Saga  EP10',
      urls: [" https://video.sibnet.ru/shell.php?videoid=4943792  ", " https://sendvid.com/embed/tzp0poer"],
      description: " ",
      title: "5",
    },

   
  {
    id:  ' Vinland Saga  EP9' ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=4943782 ", " https://sendvid.com/embed/ghp08ees "],
    description: "    ",
    title: "4",
  },


  {
    id:  'Vinland Saga  EP8'   ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=4943776 ", "  https://sendvid.com/embed/ounmd39u   "],
    description: "   ",
    title: "1121",
  },
 
  {
    id:'Vinland Saga    EP7'  ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=4943768 ", " https://sendvid.com/embed/y6wmr4t4 "],
    description: "  ",
    title: "2",
  },
 
  {
    id:  'Vinland Saga EP6'   ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=4943765  ", "  https://sendvid.com/embed/uxraj50f  "],
    description: "  ",
    title: "1119",
  },
 

    {
      id: 'Vinland Saga  EP5',
      urls: [" https://video.sibnet.ru/shell.php?videoid=4943757", " https://sendvid.com/embed/s38vg285 "],
      description: " ",
      title: "5",
    },

   
  {
    id:  'Vinland Saga   EP4' ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=4943747  ", "https://sendvid.com/embed/38qfkhgz  "],
    description: "    ",
    title: "4",
  },


  {
    id:  'Vinland Saga  EP3'   ,
    urls: ["https://video.sibnet.ru/shell.php?videoid=4943742 ", " https://sendvid.com/embed/07tc10nh"],
    description: "   ",
    title: "1121",
  },
 
  {
    id:'Vinland Saga   EP2'  ,
    urls: ["  https://video.sibnet.ru/shell.php?videoid=4943740 ", " https://sendvid.com/embed/wayx669a  "],
    description: "  ",
    title: "2",
  },
 
  {
    id:  'Vinland Saga  EP1'   ,
    urls: [" https://video.sibnet.ru/shell.php?videoid=4943733 ", " https://vidmoly.to/embed-lwdfstgrwl98.html  "],
    description: "  ",
    title: "1119",
  },
 
   



   
  ];

  // Sélectionne l'épisode actuellement en cours d'affichage basé sur l'index actuel
  const currentEpisode = episodes[currentEpisodeIndex];

  // URL de l'iframe actuelle à afficher pour l'épisode sélectionné
  const currentIframeUrl = currentEpisode.urls[currentIframeIndex];

  // Fonction pour basculer l'affichage de la section "Découvrir"
  const toggleContent = () => {
    setIsContentOpen((prevState) => !prevState); // Inverse l'état pour ouvrir ou fermer
  };

  // Fonction pour changer d'épisode en cliquant dans la section "Découvrir"
  const selectEpisode = (index) => {
    setCurrentEpisodeIndex(index); // Met à jour l'index de l'épisode actuel
    setCurrentIframeIndex(0); // Réinitialise l'index de l'iframe pour chaque nouvel épisode
    setIsContentOpen(false); // Ferme la section "Découvrir" après sélection d'un épisode
  };

  // Fonction pour afficher la première iframe en cliquant sur le premier bouton Player
  const handleFirstPlayerClick = () => {
    setCurrentIframeIndex(0); // Affiche toujours la première iframe
  };

  // Fonction pour basculer entre la deuxième et la troisième iframe avec le deuxième bouton Player
  const handleSecondPlayerClick = () => {
    setCurrentIframeIndex((prevIndex) => {
      const newIndex = prevIndex === 1 ? 2 : 1;
      console.log("Updated iframe index:", newIndex); // Affiche le nouvel index
      return newIndex;
    });
  };

  // Fonction pour naviguer à l'épisode précédent
  const goToPreviousEpisode = () => {
    setCurrentEpisodeIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setCurrentIframeIndex(0); // Réinitialise l'iframe à 0 lorsque vous changez d'épisode
  };

  // Fonction pour naviguer à l'épisode suivant
  const goToNextEpisode = () => {
    setCurrentEpisodeIndex((prevIndex) => Math.min(prevIndex + 1, episodes.length - 1));
    setCurrentIframeIndex(0); // Réinitialise l'iframe à 0 lorsque vous changez d'épisode
  };

  return (
    <div   className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm overflow-y-scroll  overflow-x-scroll  max-sm:overflow-x-hidden  max-xs:overflow-x-hidden  ">
      
      {/* Conteneur principal avec animation d'ouverture et de fermeture */}
      <div
        className={`${
          isOpen ? "opacity-100 scale-100" : "opacity-0 scale-90"
        } transition-all duration-300 relative transform bg-black    p-6 rounded-md shadow-lg w-[40%] h-[125%] top-[30%] left-[25%]
         max-sm:left-[-0%]  max-sm:w-[90%] max-sm:h-[160%]    max-sm:top-[35%]   max-xs:h-[160%]    max-xs:top-[30%] 
 max-md:left-[8%]  max-md:w-[80%] 
max-lg:left-[10%]  max-lg:w-[70%]   max-lg:h-[250%]        max-lg:top-[90%]     
 max-xl:left-[22%]  max-xl:w-[50%]     flex flex-col  


        
        `}
      >
        <main className=" relative top-40 h-screen space-y-24  ">
          <Boxevideo>
           {/* Affiche le composant Headervdo */}
             <div className="w-[98%]  relative  h-[50%]  top-[-10%]   left-2
              max-sm:left-[206%] max-sm:w-[120%]   max-sm:top-[5%]
             
             ">
            {/* iFrame pour l'URL de la vidéo de l'épisode actuel */}
            <iframe
      
              src={currentIframeUrl} // Utilise l'URL de l'iframe actuelle
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              title={`Épisode ${currentEpisode.id} : Détails Complet`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              onload="this.onload=null;"
              
            ></iframe>

                </div>


            {/* Bouton pour fermer le modal */}
            <button
              className="div6 absolute top-[60%] left-[10%] text-white hover:text-purple-400 animate-bounce text-5xl font-bold
              
                 max-sm:top-[70%]   max-sm:left-[210%]   max-xs:top-[82%]
                   max-xl:top-[70%]   max-lg:top-[89%]
              "
              onClick={onClose} // Fonction onClose passée en prop pour fermer le modal
            >
              &times;
            </button>

            {/* Boutons Player pour afficher les différentes iframes */}
            <div className="div2 flex relative top-[-10%] space-x-40  justify-center mt-4  
              
            
                max-sm:top-[5%]   max-sm:left-[220%]   max-sm:space-x-20      max-lg:top-[-10%] 
            
            
            ">
              <Player onClick={handleFirstPlayerClick} >1</Player>
              <Player onClick={handleSecondPlayerClick}>2</Player>
            </div>

            {/* Description de l'épisode actuel */}
            <p className="text-center relative top-[-10%] text-sm text-white w-[50%] mx-auto mt-4
                  max-sm:left-[220%] max-sm:top-[8%]    max-sm:w-[90%]   max-sm:text-xs
            
            
            ">
              {currentEpisode.description}
            </p>

            {/* Titre de l'épisode actuel */}
            <h1 className="div3 text-white text-center text-2xl relative top-[-6%]  
             max-sm:left-[218%]     max-sm:top-[12%]      max-xs:top-[9%]  max-xs:text-xl
             max-xl:top-[-10%]         max-lg:top-[-5%]
            "   style={style}  >
              {currentEpisode.id}
            </h1>
          </Boxevideo>

          {/* Boutons de navigation */}
          <div className=" div4 flex justify-between relative top-[-45%] mx-auto mt-8 w-1/2    
          max-sm:space-x-26     max-sm:left-[-10%]       max-sm:top-[-40%]
            max-xs:top-[-36%]        max-lg:top-[-46%] 
          
          ">
            <button
              onClick={goToPreviousEpisode}
              disabled={currentEpisodeIndex === 0}
              className=" bg-fuchsia-600 hover:opacity-80 rounded font-semibold text-white p-3 transition-all duration-200 disabled:opacity-50
              
              
              
              "
            >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 md:h-5 md:w-5 md:mr-3" viewBox="0 0 20 20" fill="currentColor">
		<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z" clip-rule="evenodd"></path>
	</svg>
            </button>





            <button
              onClick={goToNextEpisode}
              disabled={currentEpisodeIndex >= episodes.length - 1}
              className="bg-fuchsia-600 hover:opacity-80 rounded font-semibold text-white p-3 transition-all duration-200 disabled:opacity-50  relative  max-sm:left-[35%]"
              
            >
<svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 md:h-5 md:w-5 md:ml-3" viewBox="0 0 20 20" fill="currentColor">
		<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd"></path>
	</svg>
                
                
            
            </button>
          </div>
         
        {/* Section "Découvrir" pour sélectionner un épisode */}
        <div className="div5 relative top-[-50%] left-0 right-0 p-4 
             max-sm:top-[-45%]         max-md:top-[-10%]    max-xs:top-[-40%]    max-lg:top-[-45%] 
              max-xl:top-[-18%]  
        ">
           <button
            onClick={toggleContent} // Appel à la fonction toggleContent
            className="w-full bg-fuchsia-600 text-white p-4 rounded-lg shadow-md text-left flex items-center justify-between"
            style={style}
          >
            <span className="text-lg"> VOIR PLUS...</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className={`transition-transform duration-300 transform ${
                isContentOpen ? "rotate-180" : "rotate-0"
              }`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {/* Liste des épisodes à découvrir */}
          {isContentOpen && (
            <div className="mt-4 space-y-4 h-64 overflow-y-scroll">
              {episodes.map((episode, index) => (
                <div
                  key={episode.id}
                  className="p-4 rounded-lg shadow-md text-white cursor-pointer bg-gray-700 hover:bg-gray-300"
                  onClick={() => selectEpisode(index)} // Appel à selectEpisode pour afficher l'épisode
                >
                  <h3 className="text-md text-white flex items-center font-semibold">
                    <FontAwesomeIcon icon={faCirclePlay} />
                    <span className="ml-2"    >{episode.id}</span>
                  </h3>
                  <p>{episode.description}</p>
                </div>
              ))}
            </div>
            
          )}
        </div>
        </main>

      </div>
      
    </div>
    
  );
  
}

export default Modal6;
 