import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons'; // Importer l'icône





const Button = ({  onClick,  children}) => {

    return (
        <button onClick={onClick} className='bg-gray-700 custom-shadow  relative left-[68%] top-[-40%] w-32 rounded-3xl
         max-sm:top-[29%]  max-sm:left-[35%]
        max-lg:top-[29%]  max-lg:left-[35%]
         max-md:left-[68%]  max-md:top-[-40%] max-md:w-32  max-md:rounded-3xl
           max-xl:top-[29%]  max-xl:left-[38%]
        text-white  min-h-14 border-2 border-fuchsia-500/100 border-spacing-y-3 text-xl
        
        
        ' > 
        
        <span className="text-xl text-wihte-500   mr-2"> {/* Ajout d'une marge à droite */}
                <FontAwesomeIcon icon={faCirclePlay} />
            </span>
       
        play
        {children}
        
        </button>
    )
}

export default Button