import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
  return (
    <Helmet>
      {/* SEO essentiel */}
      <title>shōnen </title>
      <meta name="description" content="Regardez les meilleurs animes en streaming illimité :
       One Piece, Demon Slayer, Naruto, Attack on Titan, et bien plus. Profitez de fiches détaillées 
       sur vos personnages préférés, résumés d'épisodes, guides des saisons et actualités sur les derniers 
       épisodes. Plongez dans l'univers fascinant des shonens et des mangas." />

      <meta name="keywords" content="anime, shonen, manga, One Piece, Demon Slayer, 
      Naruto, My Hero Academia, Attack on Titan, Dragon Ball, Tokyo Revengers, personnages
       d'anime, épisodes d'anime, streaming anime, univers manga, animés populaires, séries
        japonaises, Jujutsu Kaisen, Bleach, Fullmetal Alchemist, Black Clover, Hunter x Hunter,
         Sword Art Online, Fairy Tail, Vinland Saga, Boruto, Haikyuu, Re:Zero, univers shonen,
          personnages épiques, samurais, ninjas, aventures animées, combats épiques, animation japonaise,
           culture otaku, bandes dessinées japonaises, films d'anime, anime VF, anime VOSTFR,
            plateformes de streaming anime, fiches de personnages anime, univers fantastiques, mondes animés" />
, anime, scan, , vostfr, vf, streaming, streaming vostfr, streaming vf, sans pubs, 
streaming sans pubs, streaming scan

<meta name="author" content="Équipe Shonen Universe - Passionnés d'Animes et de Mangas" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#000000" />

      {/* SEO avancé */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <meta name="shonen" content="Shonen Universe" />
      <meta name="generator" content="React" />

     
      {/* Balises iOS/Android */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="format-detection" content="telephone=no" />

     
    </Helmet>
  );
};

export default SEO;