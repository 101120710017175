import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Premierepage from './Premierepage'; 
import Modal from './composant/Modal';
import Modal1 from './Modalee/Modal1';
import Modal2 from './Modalee/Modal2';
import Modal3 from './Modalee/Modal3';
import Modal4 from './Modalee/Modal4';
import Modal5 from './Modalee/Modal5';
import Modal6 from './Modalee/Modal6';
import Modal7 from './Modalee/Modal7';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Premierepage />} />
       
        <Route path="/onepiece" element={<Modal/>} />
        <Route path="/dragonballdaima" element={<Modal1 />} />
        <Route path="/demonslayer" element={<Modal2 />} />
        <Route path="/jujutsukaisen" element={<Modal3 />} />
        <Route path="/sololevling  " element={<Modal4 />} />
        <Route path="/deatnote" element={<Modal5 />} />
        <Route path="/vinlandsaga" element={<Modal6 />} />
        <Route path="/Dororo" element={<Modal7/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;